import React, { useState, useEffect } from "react";
import moment from "moment";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { WiSunset } from "react-icons/wi";
import PropTypes from "prop-types";

import TicketLogo from "../../assets/images/ticketCompare.png";
import { selectFixture } from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import FixturesListItem from "../FixturesListItem";
import CustomHeaderButton from "../CustomHeaderButton";
import DetailsTabs from "../DetailsTabs";
import { mainDetailsTabs, mainDetailsComponents } from "../../utils";
import "./fixtureDetails.scss";
import useFetchAirports from "./useFetchAirports";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";


const FixtureDetails = ({ fixture, deviceLocation , mainDetailsTabs}) => {
  const [index, setIndex] = useState(1);
  
  const { lat, lng } = fixture || {};
  const skyscannerUrl = useFetchAirports(deviceLocation, { lat, lng }, fixture);
  useEffect(() => {
    setIndex(1);    
  }, [fixture]);
 
  const today = new Date(fixture?.event_date);
  const [checkInDay, checkInMonth, checkInYear] = moment(today)
    .subtract(2, "days")
    .format("D-M-YYYY")
    .split("-");
  const [checkOutDay, checkOutMonth, checkOutYear] = moment(today)
    .add(2, "days")
    .format("D-M-YYYY")
    .split("-");
  if(!fixture?.distance){
  mainDetailsTabs =  mainDetailsTabs.filter((item)=>{
      if(item.id!==2)return true;
      return false
    })  
  }
  return (
    <div className="fixture-details">
      {/* --- Header --- */}
      <div className="fixture-details-header">
        <FixturesListItem
          fixture={fixture}
          section="details"
          type="detailsSection"
          isDetailsScreen={true}
        />
        <div className="custom-header-buttons">
          {fixture?.stadiumWebsite && (
            <CustomHeaderButton
              bgColor="#fff"
              textColor="#5B5B5B"
              text="Website"
              url={fixture?.stadiumWebsite}
              logo={
                <img
                  style={{
                    width: "25px",
                    marginLeft: "1%",
                    marginRight: "1%",
                  }}
                  onError={(e) => e.target.src = fixture?.home_team?.logo}
                  src={fixture?.home_team?.logo}
                  alt="Home team logo"
                />
              }
            />
          )}

          <CustomHeaderButton
            bgColor="#fff"
            textColor="#379AF0"
            text="Skyscanner"
            url={skyscannerUrl || "https://www.skyscanner.net/"}
            logo={
              <WiSunset
                style={{ marginTop: "-6px" }}
                name="sunset"
                size={15}
                color="#379AF0"
              />
            }
          />
          <CustomHeaderButton
            bgColor="#1F3C75"
            textColor="#fff"
            url={`https://www.booking.com/searchresults.html?ss=${fixture?.venue}&checkin_year=${checkInYear}&checkin_month=${checkInMonth}&checkin_monthday=${checkInDay}&checkout_year=${checkOutYear}&checkout_month=${checkOutMonth}&checkout_monthday=${checkOutDay}&group_adults=1&group_children=0&no_rooms=1`}
            text="Booking"
            secondaryText=".com"
          />
          {fixture?.buyUrl && (
            <CustomHeaderButton
              bgColor="#fff"
              textColor="#5256C8"
              url={fixture?.buyUrl}
              logo={
                <img
                  src={TicketLogo}
                  style={{ width: "45px", height: "25px" }}
                  alt="ticket compare logo"
                />
              }
              text=""
            />
          )}
        </div>
      </div>

      {/* --- Body --- */}
      <div className="fixtures-body">
        {/* --- Tabs --- */}
        <DetailsTabs
          tabs={mainDetailsTabs}
          index={index}
          setIndex={setIndex}
          components={mainDetailsComponents}
          type="main"
        />
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  fixture: selectFixture,
  deviceLocation: selectUserLocationCoords,
  mainDetailsTabs: ()=>mainDetailsTabs
});

FixtureDetails.propTypes = {
  fixture: PropTypes.object,
  deviceLocation: PropTypes.object,
};

export default connect(mapStateToProps)(FixtureDetails);
