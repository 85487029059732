import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

import "./navigationTabs.scss";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const NavigationTabs = ({ data, dropdown, setDropdown }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className={`header-tabs ${dropdown && "mobile-header-tabs"}`}>
      {data.map((item) => {
        if (item.type === "spa") {
          return (
            <>
              {" "}
              <NavLink
                exact
                activeClassName="active"
                className={`header-tab-item ${
                  window.location.href.includes("search") &&
                  item.title === "Home" &&
                  "active"
                }`}
                key={item.id}
                to={item.url}
                onClick={() => setDropdown(false)}
              >
                <span>{item.title}</span>
                {/* {item.title === "All World"} */}
              </NavLink>
            </>
          );
        } else {
          return (
            <a className="header-tab-item" key={item.id} href={item.url}>
              {item.title}
            </a>
          );
        }
      })}

      {/* <div className="dropdown-container">
        <button onClick={() => setIsOpen(!isOpen)} className="nav-btn">
          welcome to
        </button>

        {isOpen && (
          <div className="dropdown-content">
            <ul>
              <li>Home</li>
              <li>Home</li>
              <li>Home</li>
            </ul>
          </div>
        )}
      </div> */}

      <Link to="#" className="mob-dropdown-item">
        Home
      </Link>
      <Link to="#" className="mob-dropdown-item">
        Home
      </Link>
      <Link to="#" className="mob-dropdown-item">
        Home
      </Link>
      <Link to="/sign-in" className="mob-dropdown-item">
        Login
      </Link>
      <Link to="#" className="mob-dropdown-item">
        Logout
      </Link>
    </div>
  );
};

NavigationTabs.propTypes = {
  data: PropTypes.array,
  dropdown: PropTypes.bool,
  setDropdown: PropTypes.func,
};

export default NavigationTabs;
