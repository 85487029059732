import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";
import Switch from "react-switch";

import AutoCompleteSearchBox from "../AutoCompleteSearchBox";
import Calendar from "../Calendar";
import RangeSlider from "../RangeSlider";
import CustomApplyButton from "../CustomApplyButton";
import {
  fetchFixturesByLocationStart,
  fetchFixturesByCountryStart,
  resetFixtures,
  checkUpdateFixtures,
  fetchMatchesByCountryStart,
  fetchIsCountry,
  matchSlitByDay,
} from "../../redux/fixtures/fixturesActions";
import { getAllDatesBetween } from "../../helpers/getAllDates";
import { fetchFormValues } from "../../redux/formValues/formValuesActions";
import { resetMapDetails } from "../../redux/map/mapActions";
import { resetFixtureDetails } from "../../redux/fixtureDetails/fixtureDetailsActions";
import { resetSidebarDetails } from "../../redux/sidebar/sidebarActions";
import {
  fetchSelectedLeague,
  checkUpdateLeagues,
  fetchSelectedStadium,
  fetchSelectedTeam,
} from "../../redux/leagues/leaguesActions";
import {
  selectFormValuesRadius,
  selectFormValuesDates,
  selectFormValuesSearchedLocation,
  selectFormValuesAddress,
  selectFormValuesCountryCode,
} from "../../redux/formValues/formValuesSelectors";
import { selectFixturesFetching } from "../../redux/fixtures/fixturesSelectors";
import "./filterByLocation.scss";
import { resetLive } from "../../redux/live/liveActions";
import { useHistory } from "react-router-dom";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";

import { selectDay } from "../../redux/formValues/formValuesActions";
import { Context } from "../../helpers/context/Context";
// import Ad from "../Ad";
import { useDispatch, useSelector } from "react-redux";

const countriesData = [
  {
    id: 1,
    name: "Germany:Bundesliga",
    short_name: "DE",
    flag_icon: "https://flagcdn.com/w160/de.png",
  },
  {
    id: 2,
    name: "Spain:La Liga",
    short_name: "ES",
    flag_icon: "https://flagcdn.com/w160/es.png",
  },
  {
    id: 3,
    name: "France:Ligue 1",
    short_name: "FR",
    flag_icon: "https://flagcdn.com/w160/fr.png",
  },
  {
    id: 4,
    name: "Italy:Serie A",
    short_name: "IT",
    flag_icon: "https://flagcdn.com/w160/it.png",
  },
  {
    id: 5,
    name: "USA:Major League Soccer",
    short_name: "US",
    flag_icon: "https://flagcdn.com/w160/us.png",
  },
  {
    id: 6,
    name: "England:Premier League",
    short_name: "GB",
    flag_icon: "https://flagcdn.com/w160/gb.png",
  },
];

const FilterByLocation = ({
  fetchFixturesByLocationStart,
  fetchFixturesByCountryStart,
  resetFixtureDetails,
  resetFixtures,
  resetMapDetails,
  resetSidebarDetails,
  fetchSelectedLeague,
  fetchSelectedStadium,
  fetchSelectedTeam,
  checkUpdateFixtures,
  checkUpdateLeagues,
  fetchFormValues,
  fetchFormValuesAll,
  dates,
  resetLive,
  isFetching,
  radius,
  coords,
  country_code,
  address,
  userLocation,
  selectDay,
  matchSlitByDay,
  setIndex,
}) => {
  const leaguesRedux = useSelector((state) => state.leagues);
  const context = useContext(Context);
  const dispatch = useDispatch();
  // const [isAllCountries, setIsAllCountries] = useState(false);
  const [startDate, setStartDate] = useState(new Date(dates[0]));
  const [endDate, setEndDate] = useState(new Date(dates[dates.length - 1]));
  const [localAddress, setLocalAddress] = useState("");
  useEffect(() => {
    const d = dates;
    setStartDate(new Date(dates[0]));
    setEndDate(new Date(dates[dates.length - 1]));
  }, [dates]);
  const history = useHistory();
  const location = coords ? coords : userLocation;
  console.log(location, userLocation);

  const updateLeagues = () => {
    checkUpdateLeagues();
  };

  const updateFixtures = () => {
    checkUpdateFixtures();
  };

  const handlePress = (isAuto, data) => {
    context.setIsAllCountries(true);
    if (isFetching) return;
    resetLive();
    resetSidebarDetails();
    resetMapDetails();
    resetFixtureDetails();
    resetFixtures();
    matchSlitByDay({});
    const datesRage = Object.keys(getAllDatesBetween(startDate, endDate));
    const currentDates = Object.keys(getAllDatesBetween(startDate, endDate));
    selectDay(moment(startDate).format("YYYY-MM-DD"));

    fetchFormValues(datesRage, currentDates);
    fetchFixturesByLocationStart(context.isAllCountries);
    //  fetchSelectedLeague([]);
    if (isAuto) {
      history.push(
        `/search?radius=${radius}&dates=${datesRage[0]},${
          datesRage[datesRage.length - 1]
        }&lat=${data.searchedLocation.lat}&lng=${
          data.searchedLocation.lng
        }&address=${data.address}&iso_a2=${data.countryCode}`
      );
    } else {
      history.push(
        `/search?radius=${radius}&dates=${datesRage[0]},${
          datesRage[datesRage.length - 1]
        }&lat=${location.lat}&lng=${
          location.lng
        }&address=${address}&iso_a2=${country_code}`
      );
    }
  };

  const getLeagueData = (name, leagues) => {
    const [country, leagueName] = name.split(":");
    const leagueData = leagues.find(
      (league) =>
        String(league.country).toLocaleLowerCase() ===
          String(country).toLocaleLowerCase() &&
        String(league.league.name).toLocaleLowerCase() ===
          String(leagueName).toLocaleLowerCase()
    );
    console.log("leagueData", leagueData);
    if (leagueData) {
      return [leagueData];
    }
    return [];
  };

  return (
    <div className="filter-by-location">
      <div className="autoComplete-container">
        <AutoCompleteSearchBox
          handlePress={handlePress}
          locationString={localAddress}
          isFetching={isFetching}
          setIndex
        />
      </div>
      <div className="countries-sections">
        {countriesData.map((ele) => {
          return (
            <img
              key={ele.id}
              src={ele.flag_icon}
              alt="flag-img"
              onError={(e) =>
                (e.target.src =
                  "https://media.api-sports.io/football/teams/14834.png")
              }
              onClick={() => {
                console.log("Name", ele);
                setLocalAddress(ele.name);
                setIndex(1);
                fetchFormValuesAll({
                  leagueSearch: ele.name,
                });
                fetchSelectedLeague(
                  getLeagueData(ele.name, leaguesRedux.leagues)
                );
                fetchSelectedStadium([]);
                fetchSelectedTeam([]);
              }}
            />
          );
        })}
      </div>

      <Calendar
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        type="filterByLocation"
      />
      {/* <div className="calendar-location-text">
        * up to 3 weeks from first to last date
      </div> */}
      <div className="slider-container">
        <div className="slider-title">Match Radius</div>
        <RangeSlider />
      </div>
      <div className="find-match-button">
        <CustomApplyButton
          text="Find Matches"
          handlePress={handlePress}
          isFetching={isFetching}
        />
      </div>
    </div>
  );
};

/*
  <CustomApplyButton
        text="Update Leagues"
        handlePress={updateLeagues}
        isFetching={isFetching}
      />

      <CustomApplyButton
        text="Update Fixtures"
        handlePress={updateFixtures}
        isFetching={isFetching}
      />
*/
const mapDispatchToProps = (dispatch) => ({
  checkUpdateFixtures: () => dispatch(checkUpdateFixtures()),
  checkUpdateLeagues: () => dispatch(checkUpdateLeagues()),
  fetchFixturesByLocationStart: (payload) =>
    dispatch(fetchFixturesByLocationStart(payload)),
  fetchFixturesByCountryStart: (payload) =>
    dispatch(fetchFixturesByCountryStart(payload)),
  fetchFormValues: (datesRage, currentDates) =>
    dispatch(fetchFormValues({ dates: datesRage, currentDates })),
  fetchFormValuesAll: (data) => dispatch(fetchFormValues(data)),
  fetchSelectedLeague: (payload) => dispatch(fetchSelectedLeague(payload)),
  fetchSelectedStadium: (payload) => dispatch(fetchSelectedStadium(payload)),
  fetchSelectedTeam: (payload) => dispatch(fetchSelectedTeam(payload)),
  resetFixtureDetails: () => dispatch(resetFixtureDetails()),
  resetFixtures: () => dispatch(resetFixtures()),
  resetMapDetails: () => dispatch(resetMapDetails()),
  resetSidebarDetails: () => dispatch(resetSidebarDetails()),
  resetLive: () => dispatch(resetLive()),
  selectDay: (payload) => dispatch(selectDay(payload)),
  matchSlitByDay: (payload) => dispatch(matchSlitByDay(payload)),
});

const mapStateToProps = createStructuredSelector({
  sliderRadius: selectFormValuesRadius,
  dates: selectFormValuesDates,
  isFetching: selectFixturesFetching,
  radius: selectFormValuesRadius,
  coords: selectFormValuesSearchedLocation,
  userLocation: selectUserLocationCoords,
  address: selectFormValuesAddress,
  country_code: selectFormValuesCountryCode,
});

FilterByLocation.propTypes = {
  checkUpdateFixtures: PropTypes.func,
  checkUpdateLeagues: PropTypes.func,
  fetchFixturesByLocationStart: PropTypes.func,
  fetchFormValues: PropTypes.func,
  resetFixtureDetails: PropTypes.func,
  resetFixtures: PropTypes.func,
  resetMapDetails: PropTypes.func,
  resetSidebarDetails: PropTypes.func,
  fetchSelectedLeague: PropTypes.func,
  sliderRadius: PropTypes.number,
  dates: PropTypes.array,
  resetLive: PropTypes.func,
  isFetching: PropTypes.bool,
  radius: PropTypes.number,
  curretDate: PropTypes.string,
  coords: PropTypes.object,
  address: PropTypes.string,
  country_code: PropTypes.string,
  userLocation: PropTypes.object,
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterByLocation);
