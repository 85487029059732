import React, { useState, useEffect } from "react";
import Slider from "rc-slider";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";

import "./rangeSlider.scss";
import { selectFormValuesRadius } from "../../redux/formValues/formValuesSelectors";
import { fetchFormValues } from "../../redux/formValues/formValuesActions";

const marks = {
  30: "30",
  60: "60",
  90: "90",
  120: "120",
  150: "150",
  180: "180",
  210: "210",
  240: "240",
  270: "270",
  300: "300"
};

const RangeSlider = ({ storeRadius, fetchFormValues }) => {
  const [radius, setRadius] = useState(storeRadius);
  useEffect(() => {
    setRadius(storeRadius);
  }, [storeRadius]);
  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: "94%"
        }}
      >
        <Slider
          min={30}
          marks={marks}
          max={300}
          step={30}
          onChange={(value) => setRadius(value)}
          onAfterChange={(value) => fetchFormValues({ radius: value })}
          defaultValue={radius}
          value={radius}
        />
      </div>
      <span
        style={{
          fontSize: "8px",
          position: "absolute",
          right: -9,
          top: 0,
          width: "6%"
        }}
      >
        KM
      </span>
    </div>
  );
};

RangeSlider.propTypes = {
  fetchFormValues: PropTypes.func,
  storeRadius: PropTypes.number
};

const mapStateToProps = createStructuredSelector({
  storeRadius: selectFormValuesRadius
});

const mapDispatchToProps = (dispatch) => ({
  fetchFormValues: (payload) => dispatch(fetchFormValues(payload))
});

export default connect(mapStateToProps, mapDispatchToProps)(RangeSlider);
