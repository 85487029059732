import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";
import { FaArrowAltCircleDown } from "react-icons/fa";
import { FaArrowAltCircleUp } from "react-icons/fa";
import {
  selectStandings,
  selectFixture,
  selectLastFiveMatches,
  selectLeagueTeams,
  selectSlicedH2h,
  selectOdds,
  selectIsFetchingFixtureDetails,
  selectIsErrorInMatchDetails,
} from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import Standings from "../Standings";
import "./matchDetails.scss";
import LastFiveMatches from "../LastFiveMatches";
import H2h from "../H2h";
import Odds from "../Odds";
import {
  fetchAttractionsByLocation,
  fetchMatchDetailsStart,
  resetMatchDetails,
} from "../../redux/fixtureDetails/fixtureDetailsActions";
import Spinner from "../Spinner";
import {
  selectLiveMatch,
  selectLiveMatchesDate,
} from "../../redux/live/liveSelectors";
import Ad from "../Ad/Ad";
// import Summary from "../Summary/Summary";

const filterStandings = (standings, home_team, away_team) => {
  if (standings) {
    let filteredGroups = {};

    for (let key in standings) {
      const group = standings[key];
      const isHomeTeamAvailable = group.find(
        (team) => team.team.id === home_team.team_id
      );
      const isAwayTeamAvailable = group.find(
        (team) => team.team.id === away_team.team_id
      );

      if (isHomeTeamAvailable || isAwayTeamAvailable) {
        filteredGroups[key] = group.filter(
          (x) =>
            x.team.id === home_team.team_id || x.team.id === away_team.team_id
        );
      }
    }

    return filteredGroups;
  }
};

const isMobile = /iPhone|iPad|iPod|Android|webOS|BlackBerry|Windows Phone/i.test(navigator.userAgent);
const MatchDetails = ({
  standings,
  fixture,
  lastFiveMatches,
  leagueTeams,
  h2h,
  odds,
  fetchMatchDetailsStart,
  isFetching,
  type,
  liveMatch,
  liveDate,
  fetchAttractionsByLocation,
  isError,
}) => {
  const [showFullText, setShowFullText] = useState(!isMobile);
  const fix = type === "home" ? fixture || {} : liveMatch || {};

  const areMatchDetailsFetched = lastFiveMatches || odds || h2h;
  const cond = areMatchDetailsFetched;
  useEffect(() => {
    let interval;
    if (!cond) {
      fetchAttractionsByLocation(type);
      fetchMatchDetailsStart(type);
    }

    if (
      type === "live" &&
      new Date(liveDate).getDate() === new Date().getDate() &&
      "events" in liveMatch
    ) {
      interval = setInterval(() => {
        fetchAttractionsByLocation(type);
        fetchMatchDetailsStart(type);
      }, 1800000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [fix, cond]);
  const filteredStandings =
    standings && filterStandings(standings, fix?.home_team, fix?.away_team);

  return (
    <div style={{ height: `100%`, overflowY: "scroll" }}>
      {isFetching && !isError ? (
        <div className="mtch-details-live-spinner">
          <Spinner />
        </div>
      ) : (
        <div className="match-details">
          {fix && fix.description && (
            <div className="match-detail-cont">
              <h2>MATCH DETAILS</h2>
              <p>
                {!showFullText
                  ? fix.description.slice(0, 160)
                  : fix.description}
              </p>
              <span
                className="see-more-btn"
                onClick={() => setShowFullText(!showFullText)}
              >
                {/* {showFullText ? "See less" : "See more"} */}
                {showFullText ? (
                  <FaArrowAltCircleUp />
                ) : (
                  <FaArrowAltCircleDown />
                )}
              </span>
            </div>
          )}
          {filteredStandings && (
            <Standings
              matchDetails={true}
              fixture={fix}
              filteredStandings={filteredStandings}
              standings={standings}
            />
          )}

          {lastFiveMatches && (
            <LastFiveMatches
              lastFiveMatches={lastFiveMatches}
              fixture={fix}
              leagueTeams={leagueTeams}
            />
          )}

          <Ad
            slot="7944567579"
            styles={{
              width: 380,
              height: 120,
              marginTop: 20,
              marginBottom: 20,
              marginLeft: 20,
            }}
          />

          {h2h && <H2h h2h={h2h} fixture={fix} />}
          {odds && <Odds odds={odds} fixture={fix} />}
        </div>
      )}
      {isError && fix && !fix.description ? (
        <div className="match-detail-error">No Match Details Found</div>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  standings: selectStandings,
  fixture: selectFixture,
  liveMatch: selectLiveMatch,
  lastFiveMatches: selectLastFiveMatches,
  leagueTeams: selectLeagueTeams,
  h2h: selectSlicedH2h,
  odds: selectOdds,
  isFetching: selectIsFetchingFixtureDetails,
  liveDate: selectLiveMatchesDate,
  isError: selectIsErrorInMatchDetails,
});

const mapDispatchToProps = (dispatch) => ({
  fetchMatchDetailsStart: (payload) =>
    dispatch(fetchMatchDetailsStart(payload)),
  resetMatchDetails: () => dispatch(resetMatchDetails()),
  fetchAttractionsByLocation: (payload) =>
    dispatch(fetchAttractionsByLocation(payload)),
});

MatchDetails.propTypes = {
  standings: PropTypes.object,
  fixture: PropTypes.object,
  liveMatch: PropTypes.object,
  lastFiveMatches: PropTypes.object,
  leagueTeams: PropTypes.object,
  h2h: PropTypes.array,
  odds: PropTypes.array,
  fetchMatchDetailsStart: PropTypes.func,
  fetchAttractionsByLocation: PropTypes.func,
  isFetching: PropTypes.bool,
  type: PropTypes.string,
  resetMatchDetails: PropTypes.func,
  liveDate: PropTypes.string,
  isError: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(MatchDetails);
