import React from "react";
import { GoSettings } from "react-icons/go";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./mobileSearchBar.scss";
import { selectUserLocationAddress } from "../../redux/userLocation/userLocationSelectors";
import { selectFormValuesAddress } from "../../redux/formValues/formValuesSelectors";
import { fetchSidebarDetails } from "../../redux/sidebar/sidebarActions";
import { trimString } from "../../helpers/trimString";
import {
  useRouteMatch,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom";
import { fetchIsMobileSidebarVisible } from "../../redux/live/liveActions";
import { useSelector } from "react-redux";
const MobileSearchBar = ({
  userLocationAddress,
  searchedLocationAddress,
  fetchSidebarDetails,
  fetchIsMobileSidebarVisible,
}) => {
  const { path } = useRouteMatch();
  const league = useSelector((state) => state.leagues);

  const handleClick = () => {
    if (path.includes("live"))
      fetchIsMobileSidebarVisible({
        isMobileSidebarVisible: true,
        isFormVisible: true,
      });
    else fetchSidebarDetails({ areFixturesFetched: false });
  };

  const renderSearchText = () => {
    if (league?.selectedTeams.length > 0) {
      const name = league.selectedTeams.map((std) => std.label).join(",");
      return trimString(name, 35) || "";
    }

    if (league?.selectedStadiums.length > 0) {
      const name = league.selectedStadiums.map((std) => std.label).join(",");
      return trimString(name, 35) || "";
    }

    if (league?.selectedLeagues.length > 0) {
      const name = league.selectedLeagues
        .map((std) => std.league.name)
        .join(",");
      return trimString(name, 35) || "";
    }

    return trimString(searchedLocationAddress || userLocationAddress, 35) || "";
  };

  return (
    <div onClick={handleClick} className="search-bar">
      <div className="search-bar-inner">
        <span className="search-bar-text">{renderSearchText()}</span>
        <div className="search-bar-icon">
          <div className="search-bar-right-icon">
            <GoSettings color="#ffffff" size={20} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  userLocationAddress: selectUserLocationAddress,
  searchedLocationAddress: selectFormValuesAddress,
});

const mapDispatchToProps = (dispatch) => ({
  fetchSidebarDetails: (payload) => dispatch(fetchSidebarDetails(payload)),
  fetchIsMobileSidebarVisible: (payload) =>
    dispatch(fetchIsMobileSidebarVisible(payload)),
});

MobileSearchBar.propTypes = {
  userLocationAddress: PropTypes.string,
  searchedLocationAddress: PropTypes.string,
  fetchSidebarDetails: PropTypes.func,
  fetchIsMobileSidebarVisible: PropTypes.func,
};
export default connect(mapStateToProps, mapDispatchToProps)(MobileSearchBar);
