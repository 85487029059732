import {
  FETCH_FILTERING_VALUES,
  RESET_FILTERING_VALUES,
} from "./filteringValuesTypes";

const INITIAL_STATE = {
  isItFiltered: false,
  selectedLeagues: [],
  isAllLeaguesChecked: true,
  sortBy:'distance'
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FILTERING_VALUES:
      return { ...state, ...action.payload };

    case RESET_FILTERING_VALUES:
      return INITIAL_STATE;
    default:
      return state;
  }
};
