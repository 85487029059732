import {
  LOGIN_FAILURE,
  LOGIN_START,
  LOGIN_SUCCESS,
  SET_STATE,
  LOGIN_REQUEST,
  LOGOUT
} from "./authTypes";

export const loginRequest = (data) => ({
  type: LOGIN_REQUEST,
  payload: data,
});

export const setAuthState = (payload) => ({
  type: SET_STATE,
  payload,
});

export const loginCallStart = () => ({
  type: LOGIN_START,
});

export const loginCallSuccess = (payload) => ({
  type: LOGIN_SUCCESS,
  payload,
});
export const logout = (payload) => ({
  type: LOGOUT,
  payload,
});

export const loginCallFailure = (payload) => ({
  type: LOGIN_FAILURE,
  payload,
});
