import React, { useContext, useEffect, useCallback } from "react";
import { connect, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { createStructuredSelector } from "reselect";
import Loader from "react-loader-spinner";
import moment from "moment";
import Spinner from "../Spinner";
import Switch from "react-switch";

import ReviewCard from "../ReviewCard";
import FixturesListItem from "../FixturesListItem";
import {
  fetchFixture,
  resetFixtureDetails,
  resetMatchDetails,
} from "../../redux/fixtureDetails/fixtureDetailsActions";
import { fetchMapDetails } from "../../redux/map/mapActions";
import { fetchSidebarDetails } from "../../redux/sidebar/sidebarActions";

import "./fixturesList.scss";
import {
  selectDynamicFixturesList,
  selectSearchBy,
  selectFixturesByLeagues,
  selectFicturesLeaguesArray,
  selectFixturesByLeaguesRadius,
  selectAllLoaders,
} from "../../redux/fixtures/fixturesSelectors";
import {
  selectMarkerSelected,
  selectMarker,
  selectInternalMap,
  selectCircle,
} from "../../redux/map/mapSelectors";
import {
  selectIsItFiltered,
  selectSortBy,
} from "../../redux/filteringValues/filteringValuesSelectors";
import { selectSupporters } from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import Ad from "../Ad/Ad";

import {
  fetchFixturesByLocationSuccess,
  fetchMatchdayLoaders,
} from "../../redux/fixtures/fixturesActions";
import { fetchSelectedLeague } from "../../redux/leagues/leaguesActions";
import { selectFormValuesRadius } from "../../redux/formValues/formValuesSelectors";
import { selectMatches } from "../../redux/fixtures/fixturesSelectors";
import {
  selectorSelectDay,
  selectFormValuesDates,
} from "../../redux/formValues/formValuesSelectors";
import { selectDay } from "../../redux/formValues/formValuesActions";
import {
  fetchFixturesByLocationStart,
  resetFixtures,
  setFixture,
  setLeagues,
} from "../../redux/fixtures/fixturesActions";

//HELPER FUNC
import { sortByLeague } from "../../helpers/sortByLeague";

//COMPONENTS
import MatchNotFound from "../MatchNotFound";
import { Context } from "../../helpers/context/Context";

const FixturesList = (props) => {
  const {
    searchBy,
    fixtures,
    fetchSidebarDetails,
    fetchFixture,
    fetchMapDetails,
    markerSelected,
    selectedLeagues,
    itemRef,
    marker,
    isItFiltered,
    resetFixtureDetails,
    supporters,
    resetMatchDetails,
    map,
    circle,
    fetchSelectedLeague,
    matches,
    selectDay,
    dates,
    currentSelectedDay,
    setFixture,
    setLeagues,
    loaders,
    sortBy,
    areFixturesDetailsFetched,
    areFixturesFetched,
  } = props;
  const context = useContext(Context);

  const { venue, distance } = marker || {};
  // const { insideRadiusFixtures, outsideRadiusFixtures, leagues } = fixtures;

  const insideRadiusFixtures = fixtures?.insideRadiusFixtures;
  const outsideRadiusFixtures = fixtures?.outsideRadiusFixtures
    ? fixtures?.outsideRadiusFixtures
    : [];
  const leagues = fixtures?.leagues;
  let commonData;
  context.isAllCountries
    ? (commonData = insideRadiusFixtures?.concat(outsideRadiusFixtures))
    : (commonData = insideRadiusFixtures);

  // const sortByLeagues = sortByLeague(commonData, leagues);

  const handleItemClick = (fixture) => {
    resetMatchDetails();
    if (!fixture) {
      return;
    }
    const { lat, lng } = fixture;
    if (marker?._id === fixture.fixture_id && !markerSelected) {
      return;
    }
    if (supporters) {
      resetFixtureDetails();
    }
    fetchFixture(fixture);

    fetchMapDetails({
      zoom: 13,
      center: { lat, lng },
      marker: fixture,
    });
    fetchSidebarDetails({
      areFixturesDetailsFetched: true,
    });

    if (!fixture.distance && map) {
      map.fitBounds(circle.getBounds(), 0);
    }
  };

  const handleCheck = (e, league_id) => {
    var obj = selectedLeagues.filter((i) => i.league_id == league_id);
    if (obj[0].isradius) {
      obj[0].isradius = false;
    } else {
      obj[0].isradius = true;
    }
    fetchSelectedLeague({ selectedLeagues });
  };

  const handlePress = (type) => {
    // if (dates.length === 1) {
    fetchSidebarDetails({
      areFixturesFetched: false,
    });
    // }

    // const index = dates.findIndex((d) => {
    //   if (d === currentSelectedDay) return true;
    // });

    // if (type === "up") {
    //   if (index + 1 === dates.length) {
    //     const day = dates[0];
    //     selectDay(day);
    //     setFixture(matches[day]);
    //     setLeagues(matches[day].leagues);
    //     fetchSidebarDetails({
    //       areFixturesDetailsFetched: false,
    //     });
    //   } else {
    //     const day = dates[index + 1];
    //     selectDay(day);
    //     setFixture(matches[day]);
    //     setLeagues(matches[day].leagues);
    //     fetchSidebarDetails({
    //       areFixturesDetailsFetched: false,
    //     });
    //   }
    // }
  };

  const league = useSelector((state) => state.leagues);
  const mapData = useSelector((state) => state.map);

  const isSearchOptions = () => {
    if (league?.selectedTeams?.length > 0) {
      return true;
    }

    if (league?.selectedStadiums?.length > 0) {
      return true;
    }

    if (league?.selectedLeagues?.length > 0) {
      return true;
    }

    return false;
  };
  useEffect(() => {
    const searchOptions = isSearchOptions(); // Call the memoized function inside useEffect

    let timerId;

    if (
      searchOptions &&
      commonData.length > 0 &&
      !mapData?.markerSelectedOnHover &&
      !mapData?.marker && context.isFirstTime
    ) {
      const fixture = commonData[0];
      const { lat, lng } = fixture;

      timerId = setTimeout(() => {
        fetchMapDetails({
          zoom: 6,
          center: { lat, lng },
          markerSelectedOnHover: fixture,
        });
        context.setIsFirstTime(null);
      }, 1000);
    }

    return () => {
      if (timerId && !areFixturesDetailsFetched && areFixturesFetched) {
        clearTimeout(timerId); // Clear the timeout if it was set
      }
    };
  }, [league, mapData, commonData, currentSelectedDay]); 

  return (
    <>
      {/* {fixtures?<div>No matches on today, pls select next day</div>:null} */}
      {markerSelected && marker && (
        <div className="stadium-info">{`${venue}, ${Math.round(
          distance
        )}Km from your location`}</div>
      )}
      <div
        className={`fixtures-list ${
          markerSelected || isItFiltered ? "shrink-fixtures-list" : ""
        }`}>
        {loaders[currentSelectedDay] ? (
          <div className="loaders-class">
            <Loader type="TailSpin" color="#5256C8" className="inner-loader" />
            <div>LOADING THE MATCHES... THANK YOU FOR YOUR PATIENCE </div>
          </div>
        ) : commonData.length > 0 ? (
          <>
            {
              // sortByLeagues.map(({ league, leagueName, fixtures }) => {
              // return (
              <>
                {/* <div key={leagueName} className="live-matches-league-name">
                    <img
                      src={league?.logo}
                      alt="logo"
                      onError={(e) => (e.target.src = league?.logo)}
                      className="live-matches-league-logo"
                    />
                    <span className="live-matches-league-text">
                      {league?.name}
                    </span>

                    <span className="live-matches-league-text">
                      {`${
                        league?.country !== "World"
                          ? "(" + league?.country + ")"
                          : ""
                      }`}
                    </span>
                  </div> */}
                {commonData ? (
                  commonData
                    .sort((a, b) =>
                      sortBy === "distance"
                        ? a.distance - b.distance
                        : a.event_timestamp - b.event_timestamp
                    )
                    .map((fixture, index) => {
                      return (
                        <div key={index}>
                          {index === 0 ? <div ref={itemRef}></div> : null}
                          <FixturesListItem
                            markerSelected={markerSelected}
                            type="listSection"
                            section="fixturesList"
                            callback={(fixture) => handleItemClick(fixture)}
                            fixture={fixture}
                            marker={marker}
                            isDetailsScreen={false}
                          />

                          {fixtures.length >= 2 && index === 1 ? (
                            <div className="review-card-wrapper">
                              <ReviewCard
                                styles={{ width: "95%", height: 140 }}
                              />
                            </div>
                          ) : null}

                          {fixtures.length && index === 5 ? (
                            <Ad
                              slot="3627060370"
                              styles={{ width: 418, height: 140 }}
                            />
                          ) : null}
                        </div>
                      );
                    })
                ) : (
                  <Spinner />
                )}
              </>
              // );
              // })
            }
            <p className="ending-text">
              That’s all for today! Try a new location or expand the radius to
              find more matches.
            </p>
          </>
        ) : (
          <div className="review-card-wrapper">
            <MatchNotFound
              handlePressUp={() => handlePress("up")}
              dates={dates}
              currentSelectedDay={currentSelectedDay}
            />
          </div>
        )}
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  fetchFixture: (fixture) => dispatch(fetchFixture(fixture)),
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
  fetchSidebarDetails: (payload) => dispatch(fetchSidebarDetails(payload)),
  resetFixtureDetails: (payload) => dispatch(resetFixtureDetails(payload)),
  resetMatchDetails: () => dispatch(resetMatchDetails()),
  fetchFixturesByLocationSuccess: (payload) =>
    dispatch(fetchFixturesByLocationSuccess(payload)),
  fetchSelectedLeague: (payload) => dispatch(fetchSelectedLeague(payload)),
  selectDay: (payload) => dispatch(selectDay(payload)),
  setFixture: (payload) => dispatch(setFixture(payload)),
  setLeagues: (payload) => dispatch(setLeagues(payload)),
  fetchMatchdayLoaders: (payload) => dispatch(fetchMatchdayLoaders(payload)),
});

const mapStateToProps = createStructuredSelector({
  searchBy: selectSearchBy,
  markerSelected: selectMarkerSelected,
  marker: selectMarker,
  isItFiltered: selectIsItFiltered,
  supporters: selectSupporters,
  map: selectInternalMap,
  circle: selectCircle,
  selectedLeagues: selectFicturesLeaguesArray,
  fixturesByLeagues: selectFixturesByLeagues,
  fixturesByLeaguesradius: selectFixturesByLeaguesRadius,
  storeRadius: selectFormValuesRadius,
  dates: selectFormValuesDates,
  currentSelectedDay: selectorSelectDay,
  matches: selectMatches,
  fixtures: selectDynamicFixturesList,
  loaders: selectAllLoaders,
  sortBy: selectSortBy,
});

FixturesList.propTypes = {
  resetMatchDetails: PropTypes.func,
  fixtures: PropTypes.object,
  fetchSidebarDetails: PropTypes.func,
  fetchFixturesByLocationSuccess: PropTypes.func,
  fetchSelectedLeague: PropTypes.func,
  fetchFixture: PropTypes.func,
  fetchMapDetails: PropTypes.func,
  searchBy: PropTypes.string,
  isFetchingFixtures: PropTypes.bool,
  markerSelected: PropTypes.bool,
  itemRef: PropTypes.object,
  marker: PropTypes.object,
  isItFiltered: PropTypes.bool,
  resetFixtureDetails: PropTypes.func,
  supporters: PropTypes.array,
  map: PropTypes.object,
  circle: PropTypes.object,
  fixturesByLeagues: PropTypes.object,
  fixturesByLeaguesradius: PropTypes.object,
  selectedLeagues: PropTypes.array,
  storeRadius: PropTypes.number,
  fetchMatchdayLoaders: PropTypes.func,
  loaders: PropTypes.object,
  areFixturesDetailsFetched: PropTypes.bool,
  areFixturesFetched: PropTypes.bool,
};

export default connect(mapStateToProps, mapDispatchToProps)(FixturesList);
