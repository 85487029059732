import React from "react";
import PropTypes from "prop-types";

import "./customHeaderButton.scss";

const CustomHeaderButton = ({
  bgColor,
  textColor,
  text,
  url,
  logo,
  secondaryText,
}) => {
  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={url}
      className={`header-btn-container ${
        text === "Skyscanner" && "skyscanner-btn"
      }
      ${text === "Website" && "website-btn"}
     `}
      style={{ backgroundColor: bgColor, color: textColor }}
    >
      <span className={`${text === "Website" && "web-btn"}`}>{logo}</span>
      <span
        className={`${text === "Website" && "web-btn-txt"} ${
          text === "Skyscanner" && "skyscanner-btn-txt"
        }`}
      >
        {text}
      </span>
      <span className="secondary-txt">{secondaryText}</span>
    </a>
  );
};

CustomHeaderButton.propTypes = {
  bgColor: PropTypes.string,
  textColor: PropTypes.string,
  text: PropTypes.string,
  url: PropTypes.string,
  secondaryText: PropTypes.string,
  logo: PropTypes.any,
};

export default CustomHeaderButton;
