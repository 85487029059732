import { API } from "@sentry/core";

export const GOOGLE_API_KEY = "AIzaSyD2EUbN8GTw0joJ629a5RiZlauA7S65-8M";
export const FOOTBALL_API_KEY ="e97360ba0c20074a5b3ca3a43e04e137";
  // export const FOOTBALL_API_KEY = "8d0d8919cbmshb632e029fa530d4p1c2148jsn24498fcd9d61";

export const FOOTBALL_API = "https://v3.football.api-sports.io";

export const COUNTRY_API =
  "https://api-football-v1.p.rapidapi.com/v2/countries";
export const lEAGUES_API =
  "https://api-football-v1.p.rapidapi.com/v2/leagues/country";
export const FIXTURES_API =
  "https://api-football-v1.p.rapidapi.com/v2/fixtures/league";
export const HEADERS = {
  "x-rapidapi-host": "v3.football.api-sports.io",
  "x-rapidapi-key": FOOTBALL_API_KEY,
};

export const FAM_HEADERS = {
  "x-rapidapi-key": FOOTBALL_API_KEY,
};
// export const FAM_API = "https://fam-api.herokuapp.com/api";
// 

export const FAM_API = process.env.REACT_APP_BRANCH  ? "https://footballaroundme.com/api" :"http://localhost:8000/API"
// export const FAM_API = "https://footballaroundme.com/api";
// export const FAM_API = "https://footballaroundme.com/api";

export const trackingId = "UA-160247376";
