import React, { useContext, useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FiSearch } from "react-icons/fi";
import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from "react-icons/ri";
import { HiOutlineArrowsExpand } from "react-icons/hi";
import { BiCollapse } from "react-icons/bi";

import { FaSortAmountDown } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import { animated } from "react-spring";
import { RiFileListLine } from "react-icons/ri";

import {
  selectFixturesList,
  selectFixturesFetching,
  selectFixturesError,
  selectSearchBy,
  selectDynamicFixturesList,
} from "../../redux/fixtures/fixturesSelectors";
import SearchForm from "../SearchForm";
import FixturesList from "../FixturesList";
import FixtureDetails from "../FixtureDetails";
import MatchesDates from "../MatchesDates";
import Spinner from "../Spinner";
import {
  fetchSidebarDetails,
  resetSidebarDetails,
} from "../../redux/sidebar/sidebarActions";
import {
  selectAreFixturesFetched,
  selectAreFixturesDetailsFetched,
} from "../../redux/sidebar/sidebarSelectors";
import MatchesNotFound from "../MatchesNotFound";
import {
  fetchFixturesByLocationFailure,
  resetFixtures,
} from "../../redux/fixtures/fixturesActions";

import {
  selectInternalMap,
  selectCircle,
  selectMarkerSelected,
} from "../../redux/map/mapSelectors";
import { fetchMapDetails, resetMapDetails } from "../../redux/map/mapActions";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";
import "./mapContent.scss";
import { selectWidth } from "../../redux/screenDimensions/screenDimensionsSelectors";
import {
  useFormProps,
  useFixturesListProps,
  useFixturesDetailsProps,
  useCloseButtonProps,
  useFilterSortProps,
  useShowFormButton,
  animateCamera,
  useShowListButton,
  useShowToggleButton,
} from "../../utils";
import ShowAllMatches from "../ShowAllMatches";
import { selectIsItFiltered } from "../../redux/filteringValues/filteringValuesSelectors";
import { resetFixtureDetails } from "../../redux/fixtureDetails/fixtureDetailsActions";
import { Context } from "../../helpers/context/Context";
import { selectFormValuesSearchedLocation } from "../../redux/formValues/formValuesSelectors";
import Tooltip from "../Tooltip";

const MapContent = ({
  fixtures,
  isFetchingFixtures,
  fetchSidebarDetails,
  areFixturesFetched,
  areFixturesDetailsFetched,
  error,
  originalFixturesList,
  fetchFixturesByLocationFailure,
  map,
  searchBy,
  fetchMapDetails,
  deviceLocation,
  circle,
  width,
  isItFiltered,
  markerSelected,
  setFilterModal,
  resetFixtureDetails,
  itemRef,
  resetAllFixture,
  resetMapAllDetails,
  toggleDrawer,
  setToggleDrawer,
}) => {
  const context = useContext(Context);

  const insideRadiusFixtures = fixtures?.insideRadiusFixtures;
  const outsideRadiusFixtures = fixtures?.outsideRadiusFixtures
    ? fixtures?.outsideRadiusFixtures
    : [];
  let commonData;
  context.isAllCountries
    ? (commonData = insideRadiusFixtures?.concat(outsideRadiusFixtures))
    : (commonData = insideRadiusFixtures);

  useEffect(() => {
    // TODO IF SOMETHIG WRONG WITH RENDER SIDEBARDETAILS
    // fetchSidebarDetails({
    //   areFixturesFetched:
    //     // originalFixturesList && originalFixturesList.length ? true : false,
    // });
  }, [originalFixturesList]);

  const handleCloseButton = (type) => {
    context.setIsMarker(false);
    let insideRadiusFixtures;
    let outsideRadiusFixtures;
    fixtures
      ? (insideRadiusFixtures = fixtures?.insideRadiusFixtures)
      : (insideRadiusFixtures = []);
    fixtures
      ? (outsideRadiusFixtures = fixtures?.outsideRadiusFixtures)
      : (outsideRadiusFixtures = []);

    let markers = [
      ...insideRadiusFixtures,
      ...outsideRadiusFixtures,
      { ...deviceLocation },
    ];
    resetFixtureDetails();
    if (width > 1324) {
      if (type === "all") {
        resetAllFixture();
        resetMapAllDetails();
        fetchSidebarDetails({
          areFixturesFetched: false,
          areFixturesDetailsFetched: false,
        });
      } else if (type === "list") {
        fetchSidebarDetails({
          areFixturesFetched: true,
          areFixturesDetailsFetched: false,
        });
      } else {
        fetchSidebarDetails({ areFixturesDetailsFetched: false });
      }
    } else {
      if (type === "all") {
        resetAllFixture();
        resetMapAllDetails();
        fetchSidebarDetails({
          areFixturesFetched: false,
          areFixturesDetailsFetched: false,
        });
      } else if (type === "list") {
        fetchSidebarDetails({
          areFixturesFetched: true,
          areFixturesDetailsFetched: false,
        });
      } else if (type === "search") {
        fetchSidebarDetails({
          areFixturesFetched: !areFixturesFetched,
          areFixturesDetailsFetched: false,
        });
      } else {
        fetchSidebarDetails({ areFixturesDetailsFetched: !areFixturesFetched });
      }
    }

    // if (map) {
    //   if (searchBy === "leagues" || (!insideRadiusFixtures.length && !outsideRadiusFixtures.length)) {
    //     fetchMapDetails({ marker: null, markerSelected: false,  zoom: 8, center: searchedLocationCoords || userLocationCoords });
    //     animateCamera(markers, map);
    //   } else {
    //     fetchMapDetails({ marker: null, markerSelected: false, zoom: 8, center: searchedLocationCoords || userLocationCoords  });

    //     if (circle) {
    //       map.fitBounds(circle.getBounds(), 0);
    //     }
    //   }
    // }

    if (map) {
      if (searchBy === "leagues" || !insideRadiusFixtures.length) {
        fetchMapDetails({ marker: null, markerSelected: false });
        // animateCamera(markers, map);
      } else {
        fetchMapDetails({ marker: null, markerSelected: false });

        if (circle) {
          map.fitBounds(circle.getBounds(), 0);
        }
      }
    }
  };

  return (
    <>
      <div className={`content`}>
        {isFetchingFixtures && width < 1000 && <Spinner />}

        {width > 1000 && (
          <animated.div
            style={useShowToggleButton}
            onClick={() => {
              setToggleDrawer(!toggleDrawer);
            }}
            className={`icon-container`}>
            <Tooltip content={toggleDrawer ? "Expand" : "Collapse"}>
              {toggleDrawer ? (
                <HiOutlineArrowsExpand color="white" size={20} />
              ) : (
                <BiCollapse color="white" size={20} />
              )}
            </Tooltip>
          </animated.div>
        )}

        <div className={`${toggleDrawer && "zeroOpacity"}`}>
          {/* <animated.div
            style={useCloseButtonProps(
              areFixturesFetched,
              areFixturesDetailsFetched
            )}
            onClick={() => {
              if (areFixturesFetched && areFixturesDetailsFetched) {
                handleCloseButton("list");
              } else if (areFixturesFetched) {
                handleCloseButton("all");
              } else {
                handleCloseButton("toggle");
              }
            }}
            className={`red-bg icon-container`}>
            <Tooltip
              content={
                areFixturesFetched && areFixturesDetailsFetched
                  ? "Back to matches list"
                  : "Back to search"
              }>
              {(areFixturesFetched || areFixturesDetailsFetched) && (
                <RiCloseLine color="white" size={25} />
              )}
            </Tooltip>
          </animated.div> */}

          <animated.div
            style={useShowFormButton(
              width,
              areFixturesDetailsFetched,
              originalFixturesList,
              areFixturesFetched
            )}
            className={`icon-container`}
            onClick={() => {
              if (width > 1300) {
                handleCloseButton("all");
              } else {
                handleCloseButton("search");
              }
            }}>
            <Tooltip
              content={
                areFixturesFetched || areFixturesDetailsFetched
                  ? "Jump to search"
                  : "Move to list"
              }>
              {areFixturesFetched || areFixturesDetailsFetched ? (
                <FiSearch color="white" size={20} />
              ) : (
                <RiFileListLine color="white" size={20} />
              )}
            </Tooltip>
          </animated.div>

          <animated.div
            style={useShowListButton(
              width,
              areFixturesDetailsFetched,
              originalFixturesList,
              areFixturesFetched
            )}
            className={`icon-container`}
            onClick={() => {
              handleCloseButton("list");
            }}>
            <Tooltip content={"Back to list"}>
              {areFixturesFetched && areFixturesDetailsFetched && (
                <RiFileListLine color="white" size={20} />
              )}
            </Tooltip>
          </animated.div>

          <animated.div
            className={`icon-container filter-icon-bg`}
            style={useFilterSortProps(
              width,
              areFixturesFetched,
              areFixturesDetailsFetched,
              error
            )}>
            <Tooltip content={"Filter"}>
              <div onClick={() => setFilterModal(true)}>
                <FiFilter color="white" size={20} />
              </div>
            </Tooltip>
          </animated.div>

          {error && (
            <div
              className="icon-container form-icon-container"
              onClick={() => fetchFixturesByLocationFailure(null)}>
              <FiSearch color="white" size={20} />
            </div>
          )}

          <animated.div
            style={useFormProps(
              width,
              areFixturesDetailsFetched,
              areFixturesFetched
            )}
            className={`form`}>
            {<SearchForm />}
            {
              //!error
            }
          </animated.div>

          <animated.div
            style={useFixturesListProps(
              width,
              areFixturesDetailsFetched,
              areFixturesFetched
            )}
            className="fixtures-list-container">
            {areFixturesFetched && (
              <MatchesDates isShow={commonData.length > 0} isDesktop={true} />
            )}
            {width > 1000 && areFixturesFetched && (
              <FixturesList
                searchBy={searchBy}
                itemRef={itemRef}
                isFetchingFixtures={isFetchingFixtures}
                areFixturesFetched={areFixturesFetched}
                areFixturesDetailsFetched={areFixturesDetailsFetched}
              />
            )}
            {markerSelected || isItFiltered ? (
              <ShowAllMatches
                itemRef={itemRef}
                type="listButton"
                route="home"
              />
            ) : null}
          </animated.div>

          <animated.div
            style={useFixturesDetailsProps(
              width,
              areFixturesDetailsFetched,
              areFixturesFetched
            )}
            className={`supporter-info`}>
            {areFixturesDetailsFetched && <FixtureDetails />}
          </animated.div>
          {/* {markerSelected || isItFiltered ? (
            <ShowAllMatches itemRef={itemRef} type="listButton" route="home" />
          ) : null} */}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  searchedLocationCoords: selectFormValuesSearchedLocation(state),
  userLocationCoords: selectUserLocationCoords(state),
  originalFixturesList: selectFixturesList(state),
  isFetchingFixtures: selectFixturesFetching(state),
  areFixturesFetched: selectAreFixturesFetched(state),
  areFixturesDetailsFetched: selectAreFixturesDetailsFetched(state),
  map: selectInternalMap(state),
  searchBy: selectSearchBy(state),
  deviceLocation: selectUserLocationCoords(state),
  circle: selectCircle(state),
  error: selectFixturesError(state),
  width: selectWidth(state),
  isItFiltered: selectIsItFiltered(state),
  markerSelected: selectMarkerSelected(state),
  fixtures: selectDynamicFixturesList(state),
});

const mapDispatchToProps = (dispatch) => ({
  fetchSidebarDetails: (payload) => dispatch(fetchSidebarDetails(payload)),
  fetchMapDetails: (payload) => dispatch(fetchMapDetails(payload)),
  fetchFixturesByLocationFailure: (payload) =>
    dispatch(fetchFixturesByLocationFailure(payload)),
  resetFixtureDetails: (payload) => dispatch(resetFixtureDetails(payload)),
  resetAllFixture: () => dispatch(resetFixtures()),
  resetMapAllDetails: () => dispatch(resetMapDetails()),
});

MapContent.propTypes = {
  userLocationCoords: PropTypes.object,
  searchedLocationCoords: PropTypes.object,
  fixtures: PropTypes.object,
  isFetchingFixtures: PropTypes.bool,
  fetchSidebarDetails: PropTypes.func,
  areFixturesFetched: PropTypes.bool,
  areFixturesDetailsFetched: PropTypes.bool,
  error: PropTypes.any,
  originalFixturesList: PropTypes.array,
  fetchFixturesByLocationFailure: PropTypes.func,
  map: PropTypes.object,
  circle: PropTypes.object,
  searchBy: PropTypes.string,
  fetchMapDetails: PropTypes.func,
  deviceLocation: PropTypes.object,
  width: PropTypes.number,
  isItFiltered: PropTypes.bool,
  markerSelected: PropTypes.bool,
  setFilterModal: PropTypes.func,
  setSortModal: PropTypes.func,
  resetFixtureDetails: PropTypes.func,
  resetMapAllDetails: PropTypes.func,
  resetAllFixture: PropTypes.func,
  itemRef: PropTypes.object,
  toggleDrawer: PropTypes.any,
  setToggleDrawer: PropTypes.func,
};

export default connect(mapStateToProps, mapDispatchToProps)(MapContent);
