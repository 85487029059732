import {
  FETCH_FIXTURE,
  RESET_FIXTURE_DETAILS,
  FETCH_SUPPORTERS_DETAILS_START,
  FETCH_SUPPORTERS_DETAILS_SUCCESS,
  FETCH_MATCH_DETAILS_START,
  FETCH_MATCH_DETAILS_SUCCESS,
  RESET_MATCH_DETAILS,
  FETCH_STANDINGS_START,
  FETCH_STADIUM_IMAGES_SUCCESS,
  FETCH_STADIUM_IMAGES_START,
  FETCH_SUMMARY_START,
  FETCH_SUMMARY_SUCCESS,
  FETCH_ATTRACTION_BY_LOCATION,
  FETCH_MATCH_DETAILS_FAIL,
  FETCH_TRAVEL_TIME,
  FETCH_TRAVEL_TIME_SUCCESS
} from "./fixtureDetailsTypes";

export const fetchFixture = (payload) => ({
  type: FETCH_FIXTURE,
  payload,
});

export const fetchAttractionsByLocation = (payload) => ({
  type: FETCH_ATTRACTION_BY_LOCATION,
  payload,
});

export const fetchTravelTime = (payload) => ({
  type: FETCH_TRAVEL_TIME,
  payload,
});

export const fetchSupportersDetailsStart = (payload) => ({
  type: FETCH_SUPPORTERS_DETAILS_START,
  payload,
});

export const fetchSupportersDetailsSuccess = (payload) => ({
  type: FETCH_SUPPORTERS_DETAILS_SUCCESS,
  payload,
});

export const fetchTravelTimeSuccess = (payload) => ({
  type: FETCH_TRAVEL_TIME_SUCCESS,
  payload,
});

export const fetchMatchDetailsStart = (payload) => ({
  type: FETCH_MATCH_DETAILS_START,
  payload,
});

export const fetchMatchDetailsSuccess = (payload) => ({
  type: FETCH_MATCH_DETAILS_SUCCESS,
  payload,
});

export const resetMatchDetails = (payload) => ({
  type: RESET_MATCH_DETAILS,
  payload
});

export const resetFixtureDetails = () => ({
  type: RESET_FIXTURE_DETAILS,
});

export const fetchStandingsStart = () => ({
  type: FETCH_STANDINGS_START,
});

export const fetchStadiumImagesStart = () => ({
  type: FETCH_STADIUM_IMAGES_START,
});

export const fetchStadiumImagesSuccess = (payload) => ({
  type: FETCH_STADIUM_IMAGES_SUCCESS,
  payload,
});

export const fetchSummaryStart = () => ({
  type: FETCH_SUMMARY_START,
});

export const fetchSummarySuccess = (payload) => ({
  type: FETCH_SUMMARY_SUCCESS,
  payload,
});

export const fetchMatchDetailsFail = (payload) => ({
  type: FETCH_MATCH_DETAILS_FAIL,
  payload,
});