import { call, takeLatest, put, all } from "redux-saga/effects";
import { LOGIN_REQUEST } from "./authTypes";
import { loginCallSuccess, loginCallFailure } from "./authActions";
import { loginCall, verifyGoogleApiCall } from "./authServices";
import { setCookie } from "../../utils/cookies";

function* loginApiAsync(action) {
  try {
    let data, error;
    if (action.payload.platform === "google") {
      const response = yield call(verifyGoogleApiCall, action.payload);
      data = response.data;
      error = response.error;
    } else {
      const response = yield call(loginCall, action.payload);
      data = response.data;
      error = response.error;
    }
    setCookie("token", data?.accessToken, 10);
    setCookie("user", JSON.stringify(data?.user), 10);
    if (data) yield put(loginCallSuccess(data?.user));
    else yield put(loginCallFailure(error));
  } catch (error) {
    yield put(loginCallFailure(error));
  }
}

function* watchAuthCall() {
  yield takeLatest(LOGIN_REQUEST, loginApiAsync);
}

export function* authSagas() {
  yield all([call(watchAuthCall)]);
}
