import React, { useState } from "react";
import "./contact.scss";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import signInImg from "../../assets/images/signin-img.png";

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    message: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    setFormData({
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: "",
      message: "",
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <div className="contact-container">
      <div className="contact-left-section">
        <div className="social-links">
          <p> FOLLOW US ON</p>
          <div className="line"></div>
          <div className="social-icons">
            <FaFacebookF />
            <FaInstagram />
            <FaTwitter />
            <FaLinkedinIn />
          </div>
        </div>
        <div className="contact-left">
          <h2 className="heading">Contact Us</h2>
          <p>Reach out to us for any query</p>

          <form onSubmit={handleSubmit}>
            <div className="input-container">
              <input
                type="text"
                placeholder="First Name"
                className="email-input"
                name="firstName"
                value={formData.firstName}
                onChange={handleInputChange}
              />
              <input
                type="text"
                placeholder="Last Name"
                className="email-input"
                name="lastName"
                value={formData.lastName}
                onChange={handleInputChange}
              />
              <input
                type="email"
                placeholder="Email Address"
                className="email-input"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
              />
              <input
                type="text"
                placeholder="Phone Number"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleInputChange}
              />
            </div>
            <textarea
              placeholder="Message"
              name="message"
              value={formData.message}
              onChange={handleInputChange}
            />
            <button type="submit" className="submit-btn">
              SUBMIT
            </button>
          </form>
        </div>
      </div>
      <div className="contact-right">
        <img className="contact-img" src={signInImg} alt="signIn-Img" />
      </div>
    </div>
  );
};

export default Contact;
