import React, { useState, useEffect } from "react";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FaDirections } from "react-icons/fa";
import { FaMapMarkerAlt } from "react-icons/fa";
import {
  selectAttractions,
  selectFixture,
} from "../../redux/fixtureDetails/fixtureDetailsSelectors";
import { selectUserLocationCoords } from "../../redux/userLocation/userLocationSelectors";
import "./supportersDetails.scss";
import { subDetailsTabs, subDetailsComponents } from "../../utils";
import { ReactComponent as PubIcon } from "../../assets/images/pub.svg";
import { ReactComponent as HotelIcon } from "../../assets/images/hotel.svg";
import { ReactComponent as RestaurantIcon } from "../../assets/images/restaurant.svg";
//COMPONENTS
import DetailsTabs from "../DetailsTabs";
import Ratings from "../Ratings";
import StadiumModalReview from "./StadiumModalReview";

//SELECTORS
import { selectStadium } from "../../redux/reviews/reviewSelectors";

//ACTION
import { fetchReviewData } from "../../redux/reviews/reviewActions";
import StadiumImages from "../StadiumImages";
import ReviewCard from "../ReviewCard";
import { fetchAttractionRecomendationTypeData } from "../../redux/attractions/attractionActions";
import AttractionModal from "../AttractionModal/attraction-modal";
import { fetchMapDetailsOnAttraction } from "../../redux/map/mapActions";

export const middleRate = (item) => {
  let count = 0;
  const a = item?.food_expense ? item.food_expense : 0;
  const b = item?.food_quality ? item.food_quality : 0;
  const c = item?.seat_view ? item.seat_view : 0;
  const d = item?.stadium_atmosphere ? item.stadium_atmosphere : 0;
  const e = item?.stadium_equipment ? item?.stadium_equipment : 0;
  if (a) ++count;
  if (b) ++count;
  if (c) ++count;
  if (d) ++count;
  if (e) ++count;
  return Number(a + b + c + d + e) / count;
};

class SupportersDetails extends React.Component {
  state = {
    index: 1,
    reviewsModal: false,
    attractionModal: false,
    attraction: null,
  };

  setIndex = (i) => {
    this.setState({ index: i });
  };
  setReviewsModal = (s) => {
    this.setState({ reviewsModal: s });
  };

  componentDidMount() {
    const {
      fetchReviewData,
      fixture: { location, venue_id },
      fetchAttractionRecomendationTypeData,
    } = this.props;

    location.sup_type = "stadiums";
    fetchReviewData({ location, venue_id });
  }
  handleClickAttraction = (attraction) => {
    const { fetchAttractionRecomendationTypeData } = this.props;
    fetchAttractionRecomendationTypeData(attraction._id);
    this.setState({ attraction, attractionModal: true });
  };
  render() {
    const { fixture, deviceLocation, stadiums, attractions } = this.props;
    const { reviewsModal, index, attractionModal, attraction } = this.state;
    const {
      venue,
      home_team: { venue_capacity },
    } = fixture;
    const { lat, lng } = deviceLocation || {};
    const countTotal = stadiums?.length
      ? stadiums?.reduce((acc, cur) => {
          return middleRate(cur) + acc;
        }, 0)
      : 0;

    // console.log("countTotal")
    // console.log(countTotal)
    console.log(stadiums);
    console.log(venue);
    console.log(fixture);
    console.log("attractions", attractions);
    return (
      <div className="supporters-details">
        <StadiumImages />
        <div className="stadium-capacity">
          <div className="stadium-capacity-venue">
            {/* <div className={"venue"}>{venue?.name}</div> */}
            <div className="stadium-rate">
              {/* <Ratings
                size="12px"
                rating={
                  countTotal? countTotal/stadiums?.length : 0
                }
                ratingCount={stadiums?.length ? stadiums?.length : 0}
              /> */}
              {stadiums?.length ? (
                <div
                  className={"stadium-reviews"}
                  onClick={() => {
                    this.setReviewsModal(true);
                  }}>
                  <span>{"see all reviews"}</span>
                </div>
              ) : null}
            </div>
          </div>
          {/* <a
            href={`https://www.google.com/maps/dir/'${fixture?.lat},${fixture?.lng}'/'${lat},${lng}'`}
            target="_blank"
            rel="noopener noreferrer"
            className="directions-btn"
          >
            Directions <FaDirections size={15} color="white" />
          </a> */}

          <h4>Supporter Attraction</h4>

          <div>
            {attractions &&
              attractions.length > 0 &&
              attractions.map((item) => (
                <div
                  className="details-container"
                  onMouseEnter={() =>
                    this.props.fetchMapDetailsOnAttraction({
                      attractionMarkerSelectedOnHover: item,
                    })
                  }
                  onMouseLeave={() =>
                    this.props.fetchMapDetailsOnAttraction({
                      attractionMarkerSelectedOnHover: null,
                    })
                  }
                  onClick={() => this.handleClickAttraction(item)}>
                  <div style={{ width: "70%" }} className="details-content">
                    {item.attraction_type.type_name === "pubs" ? (
                      <PubIcon style={{ flexShrink: 0 }} />
                    ) : item.attraction_type.type_name === "Hotel" ? (
                      <HotelIcon style={{ flexShrink: 0 }} />
                    ) : (
                      <RestaurantIcon style={{ flexShrink: 0 }} />
                    )}
                    <p style={{ wordBreak: "break-all" }}>{item.title}</p>
                  </div>

                  <div
                    className="details-content"
                    style={{ color: "#5256c8", width: "25%" }}>
                    <FaMapMarkerAlt color="#5256c8" size={"12px"} />{" "}
                    <span className="radius">
                      {item.distance !== null
                        ? ` ${item.distance.toFixed(2)} KM`
                        : "N/A"}
                    </span>
                  </div>
                </div>
              ))}
          </div>
          {/* <div className="stadium-capacity-total">
            <div className="stadium-capacity-space">{`${venue || "N/A"}, ${
              fixture.city || "N/A"
            }`}</div>
          </div> */}
        </div>
        <div className="review-card-wrapper">
          <ReviewCard styles={{ width: "95%", height: 140 }} />
        </div>
        {/* <DetailsTabs
          tabs={subDetailsTabs}
          index={index}
          setIndex={this.setIndex}
          components={subDetailsComponents}
          type="sub"
        /> */}
        {reviewsModal ? (
          <StadiumModalReview
            reviewsModal={reviewsModal}
            setReviewsModal={this.setReviewsModal}
          />
        ) : null}
        {attractionModal && (
          <AttractionModal
            setAttractionModal={() => this.setState({ attractionModal: false })}
            attractionModal={attractionModal}
            attraction={attraction}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = createStructuredSelector({
  fixture: selectFixture,
  deviceLocation: selectUserLocationCoords,
  stadiums: selectStadium,
  attractions: selectAttractions,
});

SupportersDetails.propTypes = {
  fixture: PropTypes.object,
  deviceLocation: PropTypes.object,
  fetchReviewData: PropTypes.func,
  stadiums: PropTypes.array,
  fetchAttractionRecomendationTypeData: PropTypes.func,
};

const mapDispatchToProps = {
  fetchReviewData,
  fetchAttractionRecomendationTypeData,
  fetchMapDetailsOnAttraction,
};

export default connect(mapStateToProps, mapDispatchToProps)(SupportersDetails);
