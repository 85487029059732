import { createSelector } from "reselect";

const selectFilteringValues = (state) => state.filteringValues;

export const selectFilterModalSelectedLeagues = createSelector(
  selectFilteringValues,
  (filteringValues) => filteringValues.selectedLeagues
);

export const selectFilterModalSelectedLeaguesIds = createSelector(
  selectFilterModalSelectedLeagues,
  (selectedLeagues) => {
    return selectedLeagues?.map((x) => x?.league?.id);
  }
);

export const selectIsItFiltered = createSelector(
  selectFilteringValues,
  (filteringValues) => filteringValues.isItFiltered
);

export const selectIsAllLeaguesChecked = createSelector(
  selectFilteringValues,
  (filteringValues) => filteringValues.isAllLeaguesChecked
);

export const selectSortBy = createSelector(
  selectFilteringValues,
  (filteringValues) => filteringValues.sortBy
);