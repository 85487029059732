import axios from "axios";
import { FAM_API } from "../constants";

export const getMatches = async (
  uniqueCountries,
  currentDate,
  userLocation,
  km,
  region,
  country
) => {

  

  const dates = [currentDate];


  try {
    const matches = await axios.post(`${FAM_API}/get-matches-country`, {
      uniqueCountries,
      dates,
      userLocation,
      km,
      region,
      country
    });
    
    const leaguesList = matches.data.leaguesList;
    const continentLeagues = matches.data.continentLeagues;
    const friendlyLeagues = matches.data.friendlyLeagues;
    const venueLocations = matches.data.venueLocations.filter((x) => {
      return x.statusShort !== "PST" && x.statusShort !== "CANC";
    });


    const continentFixtures = matches.data.continentFixtures.filter(
      (x) => {
        return x.statusShort !== "PST" && x.statusShort !== "CANC";
      }
    );

    const friendlyFixtures = matches.data.friendlyFixtures.filter((x) => {
      return x.statusShort !== "PST" && x.statusShort !== "CANC";
    });


    const leagues = [...continentLeagues, ...friendlyLeagues, ...leaguesList].sort((leagueA, leagueB) => {
      return leagueB.priority - leagueA.priority
    });
    
    const fixtures = [
      ...continentFixtures,
      ...friendlyFixtures,
      ...venueLocations,
    ];
    
    return { leagues, fixtures };
  } catch (error) {
    return { error: "Could not fetch matches" };
  }
};