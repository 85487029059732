import { createSelector } from "reselect";

const sidebar = (state) => state.sidebar;

export const selectAreFixturesFetched = createSelector(
  sidebar,
  (sidebar) => sidebar.areFixturesFetched
);

export const selectAreFixturesDetailsFetched = createSelector(
  sidebar,
  (sidebar) => sidebar.areFixturesDetailsFetched
);
